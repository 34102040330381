/** @format */

.postlogin-template.add-state-pension-content {
  height: 790px;
  margin-bottom: 100px;
  max-height: unset;
}

.add-state-pension-container .split-layout-right-heading-container {
  margin-bottom: 10px;
  height: 200px;
}

.add-state-pension-container .split-layout-right-form-button-container {
  position: relative;
  top: -80px;
  margin-top: unset;
}

.add-state-pension-container .content {
  height: 640px;
}

.add-state-pension-container .form-check-label {
  margin: 6px 0 0 17px;
}

.add-state-pension-instructions-container {
  padding-top: 12px;
}

/**** checkbox ****/

.checkbox-container {
  margin-left: 5px;
}

.form-check-input {
  visibility: hidden;
}

input[type='checkbox']:after {
  content: ' ';
  background-color: var(--color-white);
  height: 25px;
  width: 24px;
  display: inline-block;
  visibility: visible;
  border: 1px solid var(--color-pink-dark) !important;
}

input[type='checkbox']:checked:after {
  content: '\2714';
  font-weight: bold;
  font-size: 18px;
  color: var(--color-black);
  padding-left: 4px;
}

@media (max-width: 919px) {
  .add-state-pension-view .split-layout-right-form-button-container {
    margin: -25px;
  }

  .add-state-pension-view .postlogin-template.add-state-pension-content {
    height: 1000px !important;
  }

  .add-state-pension-view
    .postlogin-template
    .split-layout-right-inner-box-container {
    padding: 25px;
  }

  .add-state-pension-container .split-layout-right-heading-container {
    height: auto;
  }

  .checkbox-container {
    height: 80px !important;
  }
}
