/** @format */

.creating-power-of-attorney-container.centered-content {
  color: var(--color-white);
  height: 130px;
}

/* style for users who are already registered and re-subscribing */
.profile-resubscription-flow-container .centered-content {
  color: var(--color-black);
}

.creating-power-of-attorney-container .centered-content-top {
  padding: unset;
}

.profile-resubscription-flow-container .centered-content .centered-content-top {
  margin: 100px auto;
  padding: unset;
}

.centered-content .centered-content-top h1 {
  color: var(--color-white);
  z-index: 1;
  position: relative;
}

.creating-power-of-attorney-container h1 {
  font-size: 32px;
}

.profile-resubscription-flow-container
  .centered-content
  .centered-content-top
  h1 {
  text-align: center;
}

.profile-resubscription-flow-container
  .centered-content
  .centered-content-bottom
  .loading-indicator-container {
  margin-top: -150px;
}

@media (min-width: 920px) {
  .creating-power-of-attorney-container .centered-content-bottom {
    margin-top: -140px;
  }
}

@media (max-width: 919px) {
  .creating-power-of-attorney-container .centered-content-bottom {
    margin-top: -unset;
  }

  .creating-power-of-attorney-container h1 {
    font-size: inherit;
  }
}
