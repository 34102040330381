/** @format */

.split-layout {
  width: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 100vh;
  margin: -16px -31px;
}

.split-layout-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.split-layout-right-box {
  min-width: 0;
  max-width: 540px;
  margin: 0 auto;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}

.split-layout-left {
  align-self: start;
}

.split-layout-left-box-container {
  color: var(--color-white);
  display: flex;
  height: 95vh;
  position: relative;
  z-index: 1;
}

.split-layout-right-inner-box-container {
  flex: 1 1 auto;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.split-layout-right-box.prelogin-template
  .split-layout-right-inner-box-container {
  min-height: 650px;
}

.split-layout-left-box-content {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 16px;
  align-self: center;
}

.registration-user-left-background-image {
  background-image: var(--image-background-registration-user-layout);
  background-position: center !important;
}

.registration-welcome-left-background-image {
  background-image: var(--image-background-registration-welcome);
}

.split-layout-right {
  display: flex;
  flex-direction: column;
}

.split-layout-right .postlogin-template {
  flex: 1;
}

.split-layout-right-heading-container {
  height: 50px;
}

.split-layout-right-heading-container h1 {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.split-layout-right-subheading-container {
  height: 50px;
}

.split-layout-right-subheading-container h2 {
  font-size: 24px;
  text-align: left;
}

.split-layout-right-subheading-container .subheading-text {
  letter-spacing: 1px;
  margin-bottom: 2px;
  font-family: var(--font-din);
}

.split-layout-right-description-container {
  height: 320px;
}

.split-layout-right-form-button-container {
  margin-top: -12px;
}

.split-layout-right-form-button-container .button-loader {
  margin-top: -25px;
}

.postlogin-template .split-layout-right-inner-box-container .logo-bankid {
  margin-top: 120px;
}

.postlogin-template .content {
  height: 500px;
}

.validate-email-container .content {
  height: 300px;
}

.set-validity-for-poa-content .content {
  height: 199px;
}

/**
 ** Responsive screens
 **/

@media (min-width: 1200px) {
  .split-layout-right {
    padding: 10vh 25px 100vh 50px;
  }

  .split-layout-right .steps-wizard-container {
    flex: none;
  }

  .split-layout-right-box {
    width: 70%;
  }
  .split-layout-left {
    display: block;
  }
  .split-layout-right-box .start-text-container {
    display: none;
  }
  .split-layout-left-box-content .start-text-container {
    display: inline-block;
  }

  .start-text-container h1 {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 42px;
  }

  .start-text-container p {
    font-size: 26px;
    font-family: var(--font-playfair);
    line-height: 34px;
  }

  .split-layout-right-button-container,
  .split-layout-right-form-button-container {
    margin-top: 15px;
  }
}

@media (max-width: 1199px) {
  .split-layout-right {
    max-height: 500px;
    padding: 10px 0px 8vh 0px;
  }

  .split-layout-right .steps-wizard-container {
    flex: 1;
  }

  .split-layout-right-box {
    width: 90%;
  }
  .split-layout-left {
    display: none;
  }
  .split-layout-right-box .start-text-container {
    display: inline-block;
  }
  .split-layout-left-box-content .start-text-container {
    display: none;
  }
  .prelogin-template-login-page .start-text-container {
    display: none;
  }

  .start-text-container h1 {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 42px;
  }

  .start-text-container p {
    font-family: var(--font-playfair);
    line-height: 12px;
  }

  .split-layout-right-button-container,
  .split-layout-right-form-button-container {
    margin-top: 15px;
  }
}

@media screen and (min-width: 920px) {
  .split-layout-right-box.prelogin-template {
    width: 85%;
    height: 640px;
  }

  .split-layout-right-add-top-padding {
    padding-top: 8%;
  }

  .split-layout-right-inner-box-container {
    padding: 35px;
  }

  .split-layout-right-box.postlogin-template {
    height: 590px;
    max-height: 590px;
    max-width: 540px;
    width: 97%;
    margin-top: 10px;
  }

  .mobile-header-container,
  .split-layout-container .mobile-header {
    display: none;
  }

  .split-layout-right-heading-container h1 {
    font-size: 28px;
  }
}

@media screen and (max-height: 919px) {
  .split-layout-right {
    padding-top: 0;
  }

  .split-layout-right-add-top-padding {
    padding-top: 8%;
  }

  .split-layout-right-button-container,
  .split-layout-right-form-button-container {
    margin-top: -10px;
  }

  .split-layout-right-box.postlogin-template {
    height: 565px;
  }
}

@media (max-width: 919px) {
  .split-layout-right {
    padding-top: unset;
    padding-bottom: unset;
  }

  .split-layout-right-box.postlogin-template {
    height: 100vh;
    width: 100%;
    margin-top: unset;
  }

  .split-layout-right-box {
    border-radius: unset;
  }

  .prelogin-template .split-layout-right-inner-box-container {
    padding: 35px 0;
  }

  .split-layout-right-inner-box-container .login-ssn-label-container {
    margin-left: 15px;
  }

  .split-layout-right-box.prelogin-template {
    width: 100%;
    height: 100vh;
  }

  .split-layout-right-button-container {
    margin-top: 16px;
  }

  .mobile-header-container,
  .split-layout-container .mobile-header {
    display: block;
  }
}

@media (max-width: 919px) and (orientation: landscape) {
  .split-layout {
    height: 100vw;
  }
  .split-layout-right-box.prelogin-template {
    height: 100vw;
  }

  .mobile-header-container,
  .split-layout-container .mobile-header {
    display: block;
  }
}
