/** @format */

.agree-terms-container .heading-container,
.terms-and-conditions-container .heading-container {
  margin-bottom: 25px;
}

.agree-terms-container h5 {
  width: 260px;
}

.terms-and-conditions-container {
  margin-top: 38px;
}

.poa-text-container {
  padding-bottom: 22px;
  border-bottom: 1px solid var(--color-grey-semi-light);
}

.personal-detail-block {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  border-bottom: 1px solid var(--color-grey-semi-light);
  margin-bottom: 30px;
}

.personal-detail-block .personal-details-image {
  flex: 1;
  display: flex;
  align-items: center;
  width: 50px;
  height: 40px;
}

.personal-detail-block .personal-details-text {
  flex: 12;
  display: flex;
  align-items: center;
  width: 50px;
  height: 40px;
  margin-left: 14px;
}

.personal-detail-block .personal-details-text h1 {
  text-align: left;
  margin-top: 12px;
  text-transform: uppercase;
}

.personal-detail-block .personal-details-text p {
  text-align: left;
  margin: auto 0;
}

.personal-detail-block .icon-email-square,
.personal-detail-block .icon-profile-square,
.personal-detail-block .icon-location-square,
.personal-detail-block .icon-mobile-phone-square,
.personal-detail-block .icon-power-of-attorney-square {
  width: 44px;
  height: 44px;
  margin: auto 0;
}

.agree-terms-bottom-container {
  text-align: left;
  color: var(--color-black);
  flex: 1;
  margin: 47px auto 0;
}

.poa-text-container p,
.poa-text-container ul li,
.terms-and-conditions-container,
.agree-terms-bottom-container p {
  font-size: 12px;
}

#registration-flow .agree-terms-container {
  height: 1100px;
  max-height: max-content;
}

#registration-flow
  .agree-terms-container
  .split-layout-right-inner-box-container
  .content,
#registration-flow
  .approve-poa-container
  .split-layout-right-inner-box-container
  .content {
  height: unset;
}

.approve-poa-container {
  height: 780px !important;
}

.approve-poa-container .loading-indicator-container {
  width: 100px;
}

.agree-terms-button-container {
  height: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 35px;
  position: relative;
  top: 90px;
}

.approve-poa-container .approve-poa-button-container {
  height: 200px;
  margin-top: 55px;
}

.poa-text-container,
.terms-and-conditions-container {
  text-align: left;
}

.approve-poa-logo-container {
  margin: 5px 0;
}

.approve-poa-container .approve-poa-button-container button {
  margin-top: 16px;
}

.approve-poa-container .approve-poa-button-container .approve-poa-content {
  margin-bottom: 100px;
}

/* style for users who are already registered and re-subscribing*/
.profile-resubscription-flow-container .agree-terms-button-container {
  margin-top: 100px !important;
  position: unset;
  margin: auto;
  width: 300px;
}

.profile-resubscription-flow-container
  .approve-poa-container
  .approve-poa-button-container {
  margin-top: 100px !important;
  position: unset;
  top: unset;
  margin: 0 auto;
  width: 300px;
}

.agree-terms-view .approve-poa-submit-button-container {
  margin-bottom: 100px;
}

.approve-poa-view .split-layout-right-box.postlogin-template {
  max-height: unset;
  margin-top: 10px;
}

@media (max-width: 919px) {
  .agree-terms-view .split-layout-right-box.postlogin-template,
  .approve-poa-view .split-layout-right-box.postlogin-template {
    padding: 25px;
  }

  .profile-resubscription-flow-container
    .approve-poa-container
    .approve-poa-button-container {
    width: unset;
  }

  .agree-terms-view .content {
    flex: 6;
    height: unset;
  }

  .agree-terms-container {
    width: 94%;
  }

  .approve-poa-container {
    height: 1050px !important;
  }

  /*registration flow*/
  .approve-poa-container .approve-poa-button-container {
    height: 170px;
  }

  /* registration flow */
  .agree-terms-button-container {
    top: unset;
  }

  .profile-resubscription-flow-container .agree-terms-button-container {
    margin-bottom: 20px;
    width: 100%;
  }

  .agree-terms-view .approve-poa-submit-button-container,
  .approve-poa-view .approve-poa-submit-button-container {
    flex: 1;
    top: 250px;
    margin: auto -25px auto;
  }

  .agree-terms-view .icon-email-square,
  .agree-terms-view .icon-profile-square,
  .agree-terms-view .icon-location-square,
  .agree-terms-view .icon-mobile-phone-square,
  .agree-terms-view .icon-power-of-attorney-square,
  .approve-poa-view .icon-email-square,
  .approve-poa-view .icon-profile-square,
  .approve-poa-view .icon-location-square,
  .approve-poa-view .icon-mobile-phone-square,
  .approve-poa-view .icon-power-of-attorney-square {
    width: 30px;
    height: 30px;
    margin: auto 0;
  }

  .personal-detail-block .personal-details-text p {
    font-size: small;
  }

  .personal-detail-block {
    height: 38px;
    margin-bottom: 17px;
  }

  .approve-poa-logo-container .logo-fullmaktskollen {
    background-size: 73%;
    margin-left: -30px;
  }
}
