/** @format */

.personal-info-form-control {
  border: 1px solid var(--color-pink);
  height: 50px;
  width: 302px;
}

.personal-info-form-control:hover {
  border: 2px solid var(--color-pink);
}

.personal-info-container .subheading-text {
  text-transform: uppercase;
}

@media (min-width: 920px) {
  .personal-info-container .content {
    height: 460px;
  }
}

@media (max-width: 919px) {
  .personal-info-view .form-group .content .address2-container {
    flex-wrap: nowrap;
  }

  .personal-info-container .split-layout-right-heading-container,
  .personal-info-container .split-layout-right-subheading-container,
  .personal-info-container .form-group {
    padding: 25px 25px 0;
  }

  .personal-info-container .form-group {
    display: flex;
    flex-direction: column;
  }

  .personal-info-container .form-group .content {
    flex: 1;
  }

  .personal-info-container
    .form-group
    .split-layout-right-form-button-container {
    flex: 1;
    margin: 25px -25px 0;
  }

  .personal-info-view .split-layout-right-box.postlogin-template {
    height: 100%;
  }
}
