/** @format */

.set-validity-for-poa-details-list ul {
  margin-left: -21px;
}

.set-validity-for-poa-content h2 {
  font-size: 14px;
  font-weight: bold;
}

.set-validity-for-poa-content .split-layout-right-subheading-container {
  height: 30px;
}

.set-validity-for-poa-disclaimer-text-container {
  margin-top: 5px;
  line-height: normal;
  flex: 5;
  text-align: left;
}

.radio-row {
  height: 44px;
  margin-left: -6px;
}

.set-validity-for-poa-submit-button {
  text-align: center;
  margin-top: 90px;
}

/* style for users who are already registered and re-subscribing*/

.profile-set-validity-for-poa-container .content {
  display: block !important;
  width: 180px;
}

.profile-set-validity-for-poa-container {
  width: 700px;
  background-color: var(--color-white);
  margin: 0 auto;
}

.set-validity-for-poa-content .content .radio-label {
  padding-left: 25px;
}

.set-validty-for-poa-view-form .poa-validity-selector {
  display: flex;
  flex-direction: row;
  width: 350px;
  height: 90px;
}

.set-validty-for-poa-view-form .poa-validity-selector-left {
  flex: 1;
}

.set-validty-for-poa-view-form .poa-validity-selector-right {
  flex: 1;
  align-self: flex-end;
  margin-bottom: 3px;
}

@media (min-width: 1100px) {
  .set-validity-for-poa-submit-button {
    margin-top: 70px;
  }
}

@media screen and (max-height: 919px) {
  .set-validity-for-poa-submit-button {
    margin-top: 45px;
  }
}

@media (max-width: 919px) {
  .set-validty-for-poa-view .split-layout-right-box.postlogin-template {
    padding: 25px;
  }

  .set-validity-for-poa-content .content .radio-row.row {
    flex-wrap: unset !important;
  }

  .set-validity-for-poa-content .content .col-sm-1 {
    width: unset !important;
    padding-left: unset;
  }

  .set-validity-for-poa-submit-button {
    margin: auto -25px;
  }

  .set-validity-for-poa-content .content {
    padding: 0 10px;
  }

  .set-validty-for-poa-view-form {
    margin-bottom: 40px;
  }

  .set-validty-for-poa-view-form .poa-validity-selector {
    max-width: 290px;
    width: 100%;
  }

  .profile-set-validity-for-poa-container .logo-fullmaktskollen,
  .set-validity-for-poa-disclaimer .logo-fullmaktskollen {
    background-size: 73%;
    margin-left: -30px;
  }
}
