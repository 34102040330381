/** @format */

.poa-faq-view {
  margin-top: unset;
}

.poa-faq-content button {
  width: 100%;
}

.poa-faq-content h1 button {
  line-height: normal;
  margin-bottom: 25px;
}

.poa-faq-accordion-link,
.poa-faq-accordion-link:hover,
.poa-faq-accordion-link:active,
.poa-faq-accordion-link:focus {
  color: unset;
  text-decoration: unset;
  margin-left: -14px;
  box-shadow: unset;
  height: 30px;
}

.poa-faq-accordion-link p {
  margin-top: 14px;
}

.poa-faq-view .set-validity-for-poa-disclaimer {
  margin-top: unset;
}

@media (min-width: 920px) {
  .poa-faq-content h1 button {
    font-size: 20px;
  }
}

@media (max-width: 919px) {
  .poa-faq-view .postlogin-template .split-layout-right-inner-box-container {
    justify-content: space-evenly;
  }
  .poa-faq-view .split-layout-right-box.postlogin-template {
    padding: 25px;
  }
}
