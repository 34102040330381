/** @format */

.validate-email-container {
  height: 490px;
  text-align: left;
}

.validate-email-form {
  margin: 90px auto;
}

.validate-email-form-control:hover,
.validate-email-form-control:focus,
.validate-email-form-control:active {
  border: none;
  box-shadow: none;
}

.validate-email-form-label {
  position: absolute;
  top: -44px;
}

.validate-email-form-error {
  position: absolute;
  top: -27px;
  left: 65px;
}

.validate-email-container .validate-email-button {
  text-align: center;
}

.validation-error {
  color: var(--color-pink-dark);
}

.resend-link {
  cursor: pointer;
}

.resend-link:hover {
  font-weight: bold;
}

.resend-code-button,
.resend-code-button:hover,
.resend-code-button:active,
.resend-code-button:focus {
  padding: 0 !important;
  color: var(--color-black);
  text-align: left !important;
  background-color: unset;
  border: unset;
  outline: unset;
  box-shadow: none;
}

.resend-code-button:active {
  font-weight: bold;
  color: var(--color-black) !important;
  background-color: unset !important;
  border-color: unset !important;
}

.resend-code-button:hover {
  color: var(--color-grey-dark) !important;
  font-weight: bold;
  text-decoration: underline;
}

.resend-code-button:not(:disabled):not(.disabled).active:focus,
.resend-code-button:not(:disabled):not(.disabled):active:focus,
.show > .resend-code-button.dropdown-toggle:focus {
  box-shadow: none;
}

@media (min-width: 920px) {
  .validate-email-form-control {
    border: none;
    height: 50px;
    width: 350px;
    letter-spacing: 49px;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    background: var(--image-background-validation-code) no-repeat;
  }

  .resend-validation-button-loader {
    margin: unset;
  }

  .validate-email-container .validation-error {
    margin: -150px 0 210px;
  }

  .validate-email-container .validate-email-button {
    margin-top: 110px;
  }
}

@media (max-width: 919px) {
  .resend-validation-button-loader {
    margin: 20px auto;
  }
  .validate-email-form-control {
    border: none;
    height: 40px;
    width: 280px;
    letter-spacing: 39px;
    font-size: 19px;
    font-weight: bold;
    display: flex;
    background: var(--image-background-validation-code-small) no-repeat;
  }

  .validate-email-container .validation-error {
    margin: unset;
  }

  .validate-email-container .validate-email-form-error {
    left: 43px;
  }

  .validation-error-container {
    height: 100px;
  }

  .validate-email-form {
    margin: 90px auto 0 8px;
  }

  .validate-email-form .row .col {
    padding: unset;
  }

  .validate-email-container {
    padding: 25px;
  }

  .validate-email-view
    .postlogin-template
    .split-layout-right-inner-box-container {
    padding: 0 25px;
  }

  .validate-email-container .text-content {
    margin: 0 -25px;
  }

  .validate-email-container .validate-email-button {
    margin: 40px -51px 100px -57px;
  }

  #registration-flow .validate-email-container .validate-email-button {
    margin: 0px -51px 100px -57px;
  }

  .validate-email-container .content {
    height: unset;
  }
}
