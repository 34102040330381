/** @format */

.dashboard-view {
  margin: 0 auto;
}

.dashboard-container {
  background-color: var(--color-white);
  min-height: 530px;
  max-width: 1200px;
  margin: 20px auto;
}

.dashboard-content-wrapper {
  padding: 25px;
}

@media (min-width: 600px) {
  .dashboard-view {
    height: 282px;
  }
}

@media (min-width: 920px) and (max-width: 599px) {
  .dashboard-view {
    height: 40px;
    margin: 0 auto;
  }
  .dashboard-container {
    margin-top: 20px;
  }
}

@media (max-width: 919px) {
  .dashboard-view {
    margin: 0 auto;
    height: 0;
  }

  .dashboard-content-wrapper {
    font-size: small;
  }

  .dashboard-content-wrapper h1 {
    font-size: large;
  }

  .dashboard-container {
    margin: 0 -15px;
    min-height: 500px;
    border-radius: unset;
  }
}
