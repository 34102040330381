/** 
@format 
*/

.stripe-payment-view {
  margin-bottom: 100px;
  height: 1650px;
}

#payment-form #card-number-element,
#payment-form #card-expiry-element,
#payment-form #card-cvc-element {
  padding: 12px;
  border: 1px solid var(--color-pink);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-form .card-content .row {
  margin: 0 -4px 10px -4px;
}

#payment-form .card-content .col-card-expiry {
  padding-right: unset !important;
}

.stripe-payment-table {
  text-align: left;
  margin-top: 25px;
}

.stripe-payment-table .color-grey {
  color: var(--color-grey);
}

.stripe-payment-content {
  max-width: 700px;
}

.stripe-payment-content h2,
.stripe-payment-content p {
  text-align: left;
}

.postlogin-template.stripe-payment-content {
  height: 1550px;
}

.stripe-payment-content h2,
.stripe-payment-content h3 {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0px;
}

.stripe-payment-content .total-fees-large {
  font-family: var(--font-playfair);
}

.stripe-payment-content .subscription-description {
  color: var(--color-grey);
  font-size: 14px;
  font-style: italic;
  margin-top: -20px;
  letter-spacing: 0.5px;
}

.payment-details {
  display: flex;
  flex-direction: column;
}

.payment-subheading-text {
  flex: 1;
}

.payment-invoice-logo {
  flex: 1;
  margin-left: 11px;
}

.stripe-payment-table th.payment-details {
  height: 77px;
  padding-right: unset;
  margin-right: -30px;
  border-bottom: none;
}

.bordered-bottom {
  border-bottom: 1px solid var(--color-grey);
}

.stripe-payment-content button {
  width: 95%;
  margin-top: 20px;
}

.card-container-heading {
  margin: 4px 0 4px 10px;
  font-weight: bold;
  font-family: var(--font-din);
  font-size: 18px;
}

.payment-message-container {
  display: flex;
  width: 98%;
  margin-top: 25px;
}

.payment-message-logo {
  flex: 1;
}

.payment-message-logo.icon-payment-error {
  margin-top: 3px;
}

.payment-message-text {
  flex: 10;
  font-size: 14px;
  color: var(--color-pink-dark);
}

.payment-disclaimer {
  margin: 40px auto;
  font-size: 14px;
  width: 95%;
  text-align: left;
}

.payment-disclaimer p {
  text-align: justify;
  font-size: small;
}

.payment-button-loader {
  margin-top: 20px;
}

.payment-success-modal .image-success {
  margin: 100px auto 20px;
}

.payment-success-modal .modal-content {
  height: 400px;
  margin-top: 20vh;
  width: 600px;
}

.payment-success-modal .modal-body {
  width: 300px;
  margin: auto;
  text-align: justify;
  font-size: 20px;
  font-weight: bold;
}

.stripe-payment-content .loading-indicator-container {
  width: 100px;
}

.profile-resubscription-flow-container.stripe-payment-content .content form {
  text-align: center;
}

.payment-subheading-text-small-screen {
  display: none;
}

table th:last-child,
table td:last-child {
  width: 100px;
  text-align: right;
}

.stripe-payment-view .postlogin-template .content {
  height: unset;
}

.stripe-payment-view .split-layout-right-box.postlogin-template {
  max-height: unset;
}

@media (min-width: 920px) {
  .stripe-payment-content .total-fees-large {
    font-size: 28px;
  }
}

@media (max-width: 919px) {
  .stripe-payment-view {
    height: 100vh !important;
  }

  .stripe-payment-view .postlogin-template.stripe-payment-content {
    padding: 25px;
    height: 1600px;
  }

  /* .stripe-payment-view  */

  .stripe-payment-content .subscription-description {
    font-size: revert;
  }

  .stripe-payment-content .total-fees-large {
    font-size: x-large;
  }

  /* .stripe-payment-view  */
  .personal-detail-block .personal-details-image {
    margin-top: -9px !important;
  }

  /* .stripe-payment-view  */
  .personal-detail-block .personal-details-text {
    height: 18px !important;
  }

  .stripe-payment-view
    .postlogin-template
    .split-layout-right-inner-box-container
    .stripe-payment-table {
    margin-top: 20px;
  }

  .stripe-payment-view .payment-message-container p {
    padding-left: 10px;
  }

  .stripe-payment-view #payment-form button {
    margin: 25px -25px auto;
  }

  .stripe-payment-view #payment-form .card-content .col-card-expiry {
    padding-right: 15px !important;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 499px) {
  table th:first-child,
  table td:first-child {
    display: none;
  }

  .payment-subheading-text-small-screen {
    display: block;
    padding: 11px 11px 0;
    margin-bottom: -10px;
  }
}
