/** @format */

.profile-view {
  margin: 0 auto;
}

.profile-wrapper {
  margin: 20px auto;
  padding-top: 5px;
}

.profile-container .loading-indicator-container {
  width: 100px;
}

.profile-container .profile-heading h1 {
  text-transform: uppercase;
}

.profile-container .profile-container-rowitem {
  display: flex;
}

.profile-container .profile-container-left {
  flex: 1;
}

.profile-container .profile-container-right {
  flex: 2;
}

.profile-container .profile-container-left h1 {
  text-transform: uppercase;
  font-size: 20px;
}

.profile-container .line-item-block {
  display: flex;
  align-items: center;
}

.profile-container .profile-container-right .line-item-block {
  border-bottom: 1px solid var(--color-grey-semi-light);
}

.profile-container .profile-container-right .line-item-block.family-content,
.profile-container
  .profile-container-right
  .line-item-block.family-content
  .item-row {
  height: unset;
  height: auto;
}

.profile-container .profile-container-right .line-item-block:last-child {
  border-bottom: none;
}

.profile-container .item-row {
  display: flex;
  flex-direction: row;
}

.profile-container .content {
  width: 60%;
  flex: 18;
  height: auto;
  justify-items: flex-end;
  display: flex;
  word-break: break-word;
  margin-right: 10px;
  flex-direction: row;
  align-items: center;
}

.profile-container .edit-button-container {
  margin: auto;
  flex: 2;
  justify-content: center;
  align-items: center;
}

.profile-container .edit-email-button-container {
  text-align: center;
  margin: 160px -25px 20px;
}

.profile-container .icon-edit {
  background-image: var(--icon-edit);
  justify-content: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 5px;
  flex: 1;
  width: 40px;
  height: 32px;
}

.profile-container .icon-container {
  width: 56px;
  height: 56px;
  margin: auto;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.profile-container .icon-container .icon-profile-square,
.profile-container .icon-container .icon-mobile-phone-square,
.profile-container .icon-container .icon-email-square,
.profile-container .icon-container .icon-location-square,
.profile-container .icon-container .icon-salary-square,
.profile-container .icon-container .icon-family-square,
.profile-container .icon-container .icon-subscription-square {
  margin: 0 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.profile-container .icon-exclamation {
  background-image: var(--icon-exclamation);
  justify-content: flex-end;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 5px;
}

.profile-container .text-content {
  flex: 4;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-container .name-block .text-content {
  align-items: unset;
}

.profile-container .text-content input {
  margin-right: 10px;
}
.profile-container .text-content input:last-child {
  margin-right: unset;
}

.profile-container .family-content .text-content .form-control {
  flex: 4;
}

.profile-container .text-content .unit {
  flex: 1;
  margin-left: 10px;
  word-break: keep-all;
}

.profile-container .exclamation-container {
  flex: 1;
  width: 50px !important;
}

.profile-container .family-content .icon-exclamation {
  margin-top: -30px;
}

.profile-container .family-content .icon-container {
  margin-top: 50px;
}

.profile-container .family-content-left .profile-container-left {
  margin-top: 25px;
}

.address-container {
  width: 100%;
}

.address-container .address-large {
  display: block;
  width: 100%;
}

.address-container .address-duo {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.address-container .address-duo .address-medium {
  flex: 2;
  margin-right: 5px;
}

.address-container .address-duo .address-small {
  flex: 1;
}

.family-block {
  display: flex;
  flex-direction: column;
}

.family-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.family-container .decrement {
  flex: 1;
  align-self: center;
}

.family-container .input {
  flex: 2;
  align-self: center;
  margin: 0 20px;
}

.family-container .increment {
  flex: 1;
  align-self: center;
}

.icon-plus {
  background-image: var(--icon-plus);
  background-repeat: no-repeat;
  background-size: auto;
  width: 36px;
  height: 36px;
  margin: auto;
}

.icon-minus {
  background-image: var(--icon-minus);
  background-repeat: no-repeat;
  background-size: auto;
  width: 36px;
  height: 36px;
  margin: auto;
}

.child-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.child-container .decrement {
  flex: 1;
  align-self: center;
  width: 36px;
  height: 36px;
}

.child-container .input {
  flex: 2;
  align-self: center;
  margin: 0 20px;
}

.child-container .increment {
  flex: 1;
  align-self: center;
}

.children-block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.children-input {
  flex: 1;
  width: 60px;
}

.children-remove {
  flex: 1;
  margin: unset;
}

.children-age-error {
  border: 3px solid var(--color-pink-dark);
}

.profile-form-error {
  position: relative;
  width: 100px;
  left: 100px;
  margin-right: -111px;
}

.address-line-block {
  height: 95px !important;
}

.profile-button-container {
  margin-top: 20px;
}

.row-heading-container {
  display: flex;
  flex-direction: row;
}

.row-heading {
  flex: 1;
}

.row-heading-exclamation {
  flex: 1;
}

@media (min-width: 920px) {
  .profile {
    margin-left: unset;
    width: unset;
    padding-right: unset;
  }

  .profile-wrapper {
    width: 1050px;
    background-color: var(--color-white);
  }

  .profile-container .profile-heading {
    margin-bottom: 40px;
  }

  .profile-container .profile-container-right .item-row {
    width: 500px;
  }

  .profile-container .line-item-block {
    height: 120px;
  }

  .profile-container .profile-container-rowitem {
    flex-direction: row;
  }
  .profile-container .profile-container-right .line-item-block {
    padding-left: 50px;
  }
  .profile-container .edit-button-container,
  .profile-container .icon-edit {
    width: 42px;
    height: 42px;
  }
  .profile-container .icon-exclamation {
    width: 30px;
    height: 30px;
  }

  .box-icons {
    width: 54px;
    height: 54px;
  }
}

@media (max-width: 919px) {
  .profile {
    margin-left: unset;
    width: unset;
    padding-right: unset;
  }

  .profile-wrapper {
    background-color: var(--color-white);
    width: 93vw;
    margin: 40px auto;
    width: 100%;
  }

  .profile-container .profile-heading {
    margin-bottom: 25px;
  }
  .profile-container .profile-container-right .item-row {
    width: 100%;
  }
  .profile-container .line-item-block {
    height: 70px;
  }
  .profile-container .item-row {
    height: unset;
  }

  .profile-container .profile-container-rowitem {
    flex-direction: column;
  }
  .profile-container .profile-container-right .line-item-block {
    padding-left: unset;
  }
  .profile-container .edit-button-container,
  .profile-container .icon-edit {
    width: 40px;
    height: 40px;
  }
  .profile-container .icon-exclamation {
    width: 26px;
    height: 25px;
  }

  .box-icons {
    width: 45px;
    height: 45px;
  }

  .profile-container .text-content,
  .profile-container .text-content input {
    font-size: small;
    margin-right: 5px;
  }
  .profile-container .text-content:last-child,
  .profile-container .text-content input:last-child {
    margin-right: unset;
  }

  .profile-container .icon-edit {
    margin-right: 10px;
  }

  .profile-container .edit-button-container,
  .profile-container .icon-edit {
    width: 30px;
    height: 30px;
  }

  .profile-container .content {
    padding-top: unset;
  }

  .profile-container .text-content .form-control {
    height: unset;
  }

  .profile-container .text-content .form-control.input-first-name,
  .profile-container .text-content .form-control.input-last-name {
    margin-bottom: 0;
  }

  .profile-container .profile-container-left {
    margin-top: unset;
  }

  .profile-container .content {
    margin-right: unset;
  }

  .profile-container .text-content .unit {
    margin-left: unset;
  }

  .profile-button-container {
    margin: 20px -25px;
  }

  .profile-container .icon-exclamation {
    width: 20px;
    height: 20px;
    margin: 0 -10px;
  }

  .family-content {
    margin-top: 15px;
  }

  .list-item-left {
    display: none;
  }
}

@media (max-width: 767px) {
  .profile-wrapper {
    background-color: var(--color-white);
    width: 90vw;
    margin-left: -15px;
    width: calc(100% + 30px);
  }

  .profile-container .address-container .address-duo .address-small {
    margin-right: unset;
  }
  .profile-container .profile-heading h1 {
    font-size: x-large;
  }
  .profile-container .profile-container-left h1 {
    font-size: medium;
    height: 25px;
  }

  .profile-container .profile-heading {
    margin-bottom: unset;
  }

  .profile-container .profile-container-left {
    margin-top: 20px;
  }

  .profile-container .line-item-block {
    height: 60px;
  }

  .profile-container .item-row {
    height: 40px;
  }

  .profile-container .content {
    padding-top: 5px;
  }

  .box-icons {
    width: 35px;
    height: 35px;
  }

  .list-container .list-item-middle {
    padding-left: 10px;
  }

  .profile-container-right .item-col.icon-container {
    display: none;
  }

  .profile-container .profile-container-right .line-item-block {
    border-bottom: unset;
  }

  .family-block {
    margin: 10px auto;
  }

  .go-previous-button-dashboard {
    display: none;
  }

  .profile-container .text-content,
  .profile-container .text-content input {
    margin-right: 5px;
  }
  .profile-container .text-content:last-child,
  .profile-container .text-content input:last-child {
    margin-right: unset;
  }
}

@media (min-width: 410px) {
  .list-item-left {
    display: block;
  }
}
