/** @format */

.welcome-loader-wrapper {
  height: 100vh;
  width: 100%;
  display: block;
}
.loader-box {
  width: 100px;
  margin: 40vh auto;
}

@media (min-width: 920px) {
  .welcome-inner-container .content {
    height: 540px !important;
  }
}
@media (max-width: 919px) {
  .welcome-inner-container {
    padding-top: 25px;
  }

  .welcome-inner-container .content {
    height: 420px !important;
    padding: 0 20px 0 20px;
  }
}
