/** @format */

.list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.list-container .list-container-heading {
  text-transform: uppercase;
  margin: 10px 0px;
}

.list-item-container {
  padding: 10px;
}

.list-item-row {
  border-top: 1px solid var(--color-grey);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
}

.list-item-left {
  flex: 1;
}

.list-item-middle {
  flex: 3;
}

.list-item-right {
  flex: 1;
  text-align: right;
  text-align: -webkit-right;
}

.icon-go-forward {
  background-image: var(--icon-go-forward);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 1050px) {
  .icon-go-forward {
    width: 64px;
    height: 64px;
  }
}

@media (max-width: 1049px) {
  .icon-go-forward {
    width: 48px;
    height: 48px;
  }
}

@media (max-width: 599px) {
  .icon-go-forward {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 919px) {
  .list-item-container {
    padding: unset;
  }
  .list-item-left {
    flex: 1;
    word-break: keep-all;
  }
}

@media (max-width: 379px) {
  .list-item-left {
    flex: 2;
    word-break: keep-all;
  }
}

/***/

.subscription-modal .loading-indicator-container div {
  margin: auto;
}
