/** @format */

.signature-text-container {
  font-size: 16px;
}

.signature-ssn-label-container {
  width: 302px;
  height: 20px;
  font-family: var(--font-din);
  font-weight: bold;
  z-index: 1;
  letter-spacing: 0.6px;
  text-align: left;
}
.signature-power-of-attorney-container .loader-wrapper {
  position: relative;
  bottom: -100px;
}

.postlogin-template
  .split-layout-right-inner-box-container
  .signature-power-of-attorney-container
  .logo-bankid {
  margin-top: unset;
}

/* style for users who are already registered and re-subscribing*/
.profile-resubscription-flow-container .resubscription-sign-poa-container,
.profile-resubscription-flow-container .resubscription-approve-poa-container {
  margin: 0px auto;
}

.profile-resubscription-flow-container .signature-ssn-label-container {
  left: unset;
}

.profile-resubscription-flow-container .signature-ssn-label-container p {
  margin: unset;
}

.sign-poa-view .split-layout-right-box.postlogin-template {
  min-height: 640px;
}

.sign-poa-view .split-layout-right-inner-box-container {
  min-height: 650px;
}

.sign-poa-view .same-device-container {
  margin-top: unset;
}

@media (min-width: 920px) {
  .split-layout-right-inner-box-container .sign-poa-heading {
    font-size: 20px;
    margin-top: 60px;
  }

  /* style for users who are already registered and re-subscribing*/
  .profile-resubscription-flow-container .resubscription-sign-poa-container {
    width: min-content;
    height: 450px;
  }

  .signature-power-of-attorney-container {
    margin-bottom: unset;
  }

  .signature-ssn-label-container {
    margin: 34px auto -34px;
  }
}

@media (max-width: 919px) {
  .sign-poa-view .postlogin-template .split-layout-right-inner-box-container {
    padding: 25px;
  }

  /* style for users who are already registered and re-subscribing*/
  .profile-resubscription-flow-container .resubscription-sign-poa-container,
  .profile-resubscription-flow-container .resubscription-approve-poa-container {
    width: unset;
  }

  .signature-power-of-attorney-container {
    margin: 200px -25px auto;
  }

  .split-layout-right-inner-box-container .sign-poa-heading {
    font-size: 20px;
    margin-top: 10px;
  }

  .dashboard-view .sign-poa-heading {
    margin-top: 10px;
  }

  .dashboard-view .signature-text-container {
    padding-top: 33px;
  }

  .signature-ssn-label-container {
    padding: 10px;
    margin: 10px 0 -22px;
    height: 25px;
  }
}
