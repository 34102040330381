/** @format */

.modal-dialog {
  max-width: unset !important;
  max-width: 766px;
  width: 100vw;
  height: 48%;
  max-height: auto;
  margin: 15% auto;
}

.subscription-modal .modal-content {
  margin: 0 auto;
  height: 500px;
  margin-top: 20vh;
}
.subscription-modal .modal-container {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 350px;
}
.subscription-modal .subscription-modal-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  text-align: -webkit-center;
  flex: 2;
}

.subscription-modal .subscription-modal-body {
  flex: 3;
}

.subscription-modal .subscription-modal-buttons {
  display: flex;
}

.subscription-modal .subscription-modal-icon {
  flex: 1;
}
.subscription-modal .subscription-modal-heading {
  font-weight: bold;
  text-align: center;
  margin: 25px auto;
  flex: 1;
}

.subscription-modal .btn {
  flex: 1;
}

.subscription-modal .loading-indicator-container {
  width: 100px;
  margin-top: 16%;
}

.subscription-modal .icon-exclamation-black {
  background-image: var(--icon-exclamation-black);
  justify-content: flex-end;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 5px;
  width: 30px;
  height: 30px;
}

@media (min-width: 900px) {
  .subscription-modal .modal-content {
    width: 650px;
  }
  .subscription-modal .modal-container {
    width: 500px;
  }
  .subscription-modal .subscription-modal-buttons {
    flex-direction: row;
  }
  .subscription-modal .btn {
    margin: auto 6px;
  }
}

@media (max-width: 899px) {
  .subscription-modal .modal-content {
    width: 75vw;
  }
  .subscription-modal .modal-container {
    width: 65vw;
  }
  .subscription-modal .subscription-modal-buttons {
    flex-direction: column;
    align-self: center;
    width: 100%;
  }
  .subscription-modal .btn {
    margin: 6px auto;
  }
}

@media (max-width: 549px) {
  .subscription-modal .modal-content {
    width: 90vw;
  }

  .subscription-modal .subscription-modal-buttons {
    flex-direction: column;
    align-self: center;
  }
  .subscription-modal .btn {
    margin: 6px auto;
    width: 70vw;
  }
}

@media (max-width: 499px) {
  .subscription-modal {
    top: -50px;

    font-size: small;
  }

  .subscription-modal .modal-content {
    width: 98vw;
    border-radius: unset;
  }
  .subscription-modal .modal-container {
    width: 80vw;
    height: 400px;
  }
  .subscription-modal .subscription-modal-buttons {
    flex-direction: column;
    align-self: center;
  }
  .subscription-modal .btn {
    margin: 6px auto;
  }
}
