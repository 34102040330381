/** @format */

.start-page-heading {
  flex: 1;
  text-transform: uppercase;
  margin-top: 15px;
}

.start-text-container {
  line-height: normal;
  flex: 3;
  line-height: normal;
}

.split-layout-right-inner-box-container .logo-bankid {
  flex: 1;
}
