/** @format */

.btn-blue {
  background-color: var(--color-blue-light);
}

.btn-blue:disabled {
  color: var(--color-grey-light) !important;
  background-color: var(--color-blue-light) !important;
  border-color: var(--color-blue-light) !important;
}

.btn-pink {
  color: var(--color-white);
  background-color: var(--color-pink);
  border-color: var(--color-pink);
  border-radius: 0;
}

.btn-pink:hover {
  color: var(--color-pink) !important;
  background-color: var(--color-white) !important;
  border-color: var(--color-pink) !important;
  border-width: 2px;
}

.btn-pink:active {
  color: var(--color-pink) !important;
  background-color: var(--color-pink-light) !important;
  border-color: var(--color-pink-light) !important;
}

.btn-pink:focus {
  color: var(--color-white) !important;
  background-color: var(--color-pink) !important;
  border-color: var(--color-white) !important;
  box-shadow: 0 0 0 0.2rem var(--color-pink) !important;
}

.btn-pink:focus-within {
  color: var(--color-pink) !important;
  background-color: var(--color-pink-light) !important;
  border-color: var(--color-pink-light) !important;
}

.btn-pink:disabled {
  background-color: var(--color-pink-light) !important;
  border-color: var(--color-pink-light) !important;
}

.btn-pink-transparent {
  color: var(--color-pink) !important;
  background-color: var(--color-white) !important;
  border: 2px solid var(--color-pink-dark) !important;
  border-radius: 0;
}

.btn-pink-transparent:hover {
  color: var(--color-white) !important;
  background-color: var(--color-pink) !important;
  border-color: var(--color-pink-dark) !important;
  border-width: 2px;
}

.btn-pink-transparent:active {
  color: var(--color-white) !important;
  background-color: var(--color-pink-light) !important;
  border-color: var(--color-pink-dark) !important;
}

.btn-pink-transparent:focus {
  color: var(--color-pink) !important;
  background-color: var(--color-white) !important;
  border-color: var(--color-pink-dark) !important;
  box-shadow: 0 0 0 0.2rem var(--color-pink) !important;
}

.btn-pink-transparent:focus-within {
  color: var(--color-pink) !important;
  background-color: var(--color-pink-light) !important;
  border-color: var(--color-pink-light) !important;
}

.btn-centered {
  flex: 1;
  margin: auto;
}

@media (max-width: 919px) {
  .btn {
    width: 100%;
  }
}

@media (max-width: 549px) {
  .btn {
    width: -moz-available;
    width: -webkit-fill-available;
    min-width: 250px;
  }
}
